<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/service' }">客服</el-breadcrumb-item>
      <el-breadcrumb-item>编辑客服</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.pageForm.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="formRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="客服昵称">
          <el-input v-model="pageForm.nick" placeholder="请输入客服昵称"></el-input>
        </el-form-item>
        <el-form-item label="客服头像">
          <upload :url="pageForm.avatar" :filed="'avatar'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="客服电话">
          <el-input v-model="pageForm.phone" placeholder="请输入客服电话"></el-input>
        </el-form-item>
        <el-form-item label="客服微信二维码">
          <upload :url="pageForm.ewm" :filed="'ewm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">修 改</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
export default {
  name: 'Edit',
  components: {
    Upload
  },
  data () {
    return {
      pageForm: {
        id: this.$route.params.id,
        sort: 0,
        nick: '',
        avatar: '',
        phone: '',
        ewm: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/pc-service', { params: { id: this.$route.params.id } })
      if (res.status === 200) {
        this.pageForm.sort = res.data.sort
        this.pageForm.nick = res.data.nick
        this.pageForm.avatar = res.data.avatar
        this.pageForm.phone = res.data.phone
        this.pageForm.ewm = res.data.ewm
      } else {
        this.$message.error(res.msg)
      }
    },
    async onSubmit () {
      if (!this.pageForm.nick) {
        this.$message.error('请填写客服昵称')
        return false
      }
      if (!this.pageForm.avatar) {
        this.$message.error('请上传客服头像')
        return false
      }
      if (!this.pageForm.ewm) {
        this.$message.error('请上传客服微信二维码')
        return false
      }
      if (!this.pageForm.phone) {
        this.$message.error('请填写客服手机号')
        return false
      }
      const { data: res } = await this.$http.put('/pc-service', this.pageForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.$router.push('/admin/apps/pc/service')
      } else {
        this.$message.error(res.msg)
      }
    },
    cancel () {
      this.$router.push('/admin/apps/pc/service')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
</style>
